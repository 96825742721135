import { Gondor } from "../Gondor";

export function find(catalog: any) {
  let catalogs : any[];
  catalogs = [{name:'',id:'0'}];
  catalogs.shift();
  switch (catalog) {
    case "months":
      catalogs = [
        {
          name: "ENE",
          id: "1",
        },
        {
          name: "FEB",
          id: "2",
        },
        {
          name: "MAR",
          id: "3",
        },
        {
          name: "ABR",
          id: "4",
        },
        {
          name: "MAY",
          id: "5",
        },
        {
          name: "JUN",
          id: "6",
        },
        {
          name: "JUL",
          id: "7",
        },
        {
          name: "AGO",
          id: "8",
        },
        {
          name: "SEP",
          id: "9",
        },
        {
          name: "OCT",
          id: "10",
        },
        {
          name: "NOV",
          id: "11",
        },
        {
          name: "DIC",
          id: "12",
        },
      ];
      break;

  }
  return catalogs;
}

export function _find(path: any) {
  return Gondor.http.get(path);
}


import { defineComponent } from "vue";
export default defineComponent({
  name: "AdContainer",
  props: {
    adUnitPath: {
      type: String,
      required: true,
    },
    size: {
      type: Array,
      required: true,
    },
    viewportSize1: {
      type: Array,
      required: true,
    },
    slotSize1: {
      type: Array,
      required: true,
    },
    viewportSize2: {
      type: Array,
      required: true,
    },
    slotSize2: {
      type: Array,
      required: true,
    },
    divName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    (window as any).googletag = (window as any).googletag || { cmd: [] };
    (window as any).googletag.cmd.push(() => {
      const fluidAdSlot = (window as any).googletag
        .defineSlot(this.adUnitPath, this.size, this.divName)
        .addService((window as any).googletag.pubads());

      const mapping = (window as any).googletag
        .sizeMapping()
        .addSize(this.viewportSize1, this.slotSize1)
        .addSize(this.viewportSize2, this.slotSize2)
        .build();

      fluidAdSlot.defineSizeMapping(mapping);

      (window as any).googletag.enableServices();
    });

    (window as any).googletag.cmd.push(() => {
      (window as any).googletag.display(this.divName);
    });
  },
});

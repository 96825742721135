
import { defineComponent, ref } from "vue";
import * as ClickServices from "../api/helpers/Click";
import { useGtm } from "vue-gtm";
import * as ApplicationServices from "../api/helpers/Application";
import * as CardServices from "../api/helpers/Cards";
import Spinner from "../components/Spinner.vue";
import Cards from "../components/Cards.vue";
import { getCurrentInstance } from "vue";
import * as FacebookServices from "../api/helpers/Facebook";
import "particles.js";
export default defineComponent({
  name: "Results",
  components: {
    Spinner,
    Cards,
  },
  data() {
    return {
      newEmail: sessionStorage.getItem("newEmail"),
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
      sourceId: sessionStorage.getItem("CHID"),
    };
  },
  setup() {
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;
    const toast = app?.appContext.config.globalProperties.$toast;
    const sourceId = sessionStorage.getItem("CHID");
    var cards = ref([]);
    var loading = ref(false);
    var redirect = ref(false);
    var foundVehicles = ref(false);
    const getCards = async (leadId: string | null) => {
      loading.value = true;

      try {
        let data;

        if (leadId) {
          const res = await CardServices.find(leadId);
          data = res.data.data;

          if (data.length) {
            foundVehicles.value = true;
          } else {
            const res = await CardServices.findAll();
            data = res.data.data;
          }
        } else {
          const res = await CardServices.findAll();
          data = res.data.data;
        }

        data = data.map((c: any) => {
          c.content = c.content.split(String.fromCharCode(10));
          return c;
        });

        cards.value = data;
        loading.value = false;

        if (cards.value.length === 1) {
          loading.value = true;
          await new Promise((resolve) => setTimeout(resolve, 2500));

          loading.value = false;
          redirect.value = true;

          if (leadId) {
            const params = {
              leadId: leadId,
              cardId: cards.value[0]["id"],
              source: sourceId,
            };
            ClickServices.click(params);
          }

          await new Promise((resolve) => setTimeout(resolve, 2500));

          window.location.href = cards.value[0]["urls"][0]["url"];
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle the error accordingly
      }
    };

    return {
      cards,
      getCards,
      loading,
      redirect,
      foundVehicles,
      toastTime,
    };
  },
  mounted() {
    FacebookServices.pageView();
    this.initParticles();
    this.loading = true;
    window.scrollTo(0, 0);
    document.title = process.env.VUE_APP_TITLE + " | Resultados";

    this.getCards(this.leadId);

    if (this.newEmail != null) {
      window.dataLayer?.push({
        leadId: this.leadId,
        applicationId: this.applicationId,
        newEmail: this.newEmail,
        eventID: sessionStorage.getItem("eventID"),
      });
      FacebookServices.lead();
      this.triggerEvent();
      sessionStorage.removeItem("newEmail");
    }
    const application = this.applicationId?.toString();
    if (application) {
      ApplicationServices.update({ completed: true }, application);
    }
  },
  // beforeCreate() {
  //   const leadId = sessionStorage.getItem("leadId");
  //   const applicationId = sessionStorage.getItem("applicationId");
  //   if (!leadId || !applicationId) {
  //     this.$router.push("../registro");
  //   }
  // },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 250,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 10,
            },
          },
          opacity: {
            value: 0.75,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              opacity_min: 0.2,
              sync: true,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 0,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
    handleOnClick(cardId: number) {
      const params = {
        leadId: this.leadId,
        cardId: cardId,
        source: this.sourceId,
      };
      if (this.leadId) {
        ClickServices.click(params);
      }
    },
    triggerEvent() {
      const gtm = useGtm();
      gtm?.trackEvent({
        event: "FireGTM",
      });
    },
  },
});

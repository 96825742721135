import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"text-align":"center"},
  class: "my-5"
}
const _hoisted_2 = {
  key: 1,
  class: "row"
}
const _hoisted_3 = { class: "col-md-6 col-lg-6 col-sm-12" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 0,
  class: "p-error ml-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_spinner, {
            text: "",
            color: _ctx.spinnerColor,
            textColor: _ctx.spinnerTextColor
          }, null, 8, ["color", "textColor"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catalogData, (category) => {
            return (_openBlock(), _createElementBlock("div", {
              key: category.id
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass([
              _ctx.selectedcatalogData.includes(category)
                ? 'field-checkbox-checked'
                : 'field-checkbox',
              {
                'p-invalid':
                  _ctx.isRequired && !_ctx.selectedcatalogData.length && _ctx.isSubmitted,
              },
            ]),
                  onClick: ($event: any) => (_ctx.check(category))
                }, [
                  _createVNode(_component_Checkbox, {
                    class: _normalizeClass([
                _ctx.selectedcatalogData.includes(category)
                  ? 'p-checkbox-checked'
                  : 'p-checkbox',
                {
                  'p-invalid':
                    _ctx.isRequired && !_ctx.selectedcatalogData.length && _ctx.isSubmitted,
                },
              ]),
                    id: category.key,
                    name: "category",
                    value: category,
                    modelValue: _ctx.selectedcatalogData,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedcatalogData) = $event))
                  }, null, 8, ["class", "id", "value", "modelValue"]),
                  _createElementVNode("label", {
                    class: _normalizeClass([
                _ctx.selectedcatalogData.includes(category)
                  ? 'label-for-check-checked'
                  : 'label-for-check'
              ]),
                    for: category.key
                  }, _toDisplayString(category.name), 11, _hoisted_5)
                ], 10, _hoisted_4)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.isRequired && !_ctx.selectedcatalogData.length && _ctx.isSubmitted)
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.validationLabel), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
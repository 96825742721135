import { Gondor } from "../Gondor";

const ACTIONS = {
  CARDS: "/card?systemId={systemId}&order=order:asc&active=1",
  CARDS_ITEM: "/lead/{leadId}/card",
};

export function find(leadId: string) {
  return Gondor.http.get(ACTIONS.CARDS_ITEM.replace("{leadId}", leadId));
}

export function findAll(params?: any) {
  return Gondor.http.get(ACTIONS.CARDS.replace("{systemId}", process.env.VUE_APP_SYSTEM_ID));
}

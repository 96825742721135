
  import { defineComponent } from "vue";
  import Card from "primevue/card";

  export default defineComponent({
    name: "Cards",
    components: {
      Card,
    },
    props: {
      name: String,
      src: String,
      aside: String,
      content: Array,
      urls: Array,
      id: Number,
      handleOnClick: Function
    }
  });

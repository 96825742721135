
import { defineComponent } from "vue";
import AdContainer from "@/components/AdContainer.vue";
import PanelMenu from "primevue/panelmenu";
import Carousel from "primevue/carousel";
import AdGoatConfig from "@/api/helpers/Adgoat";
import "particles.js";
import * as FacebookServices from "../api/helpers/Facebook";
import _ from "underscore";
declare global {
  interface Window {
    particlesJS: any;
  }
}

window.particlesJS = window.particlesJS || {};
export default defineComponent({
  name: "Home",
  components: {
    PanelMenu,
    Carousel,
    AdContainer,
  },
  data() {
    return {
      AdGoatConfig,
      name: process.env.VUE_APP_TITLE,
      items: [
        {
          key: "0",
          label: "Paso. 1 Haz clic en “Quiero conocer mi auto ideal”",
          items: [
            {
              label: "Quiero conocer mi auto ideal",
              url: "registro",
            },
          ],
        },
        {
          key: "1",
          label: "Paso. 2 Contesta las preguntas honestamente",
          items: [
            {
              label:
                "Nuestro sistema inteligente, analizará tus respuestas, y te mostrará las opciones de automóviles que podrías comprar en este momento.",
            },
          ],
        },
        {
          key: "2",
          label: "Paso. 3 Selecciona la opción que deseas",
          items: [
            {
              label:
                "Selecciona el auto que deseas, y te enviaremos con el vendedor, para que puedas conocer más detalles sobre el mismo.",
            },
          ],
        },
      ],
      expandedKeys: {0:true},
      clients: [
        {
          name: "Bernardo Ventura",
          description:
            "“La verdad es un servicio único, algo nuevo que funciona bien.”",
          image: "/img/testi-1.09249421.jpg",
        },
        {
          name: "José Antonio",
          description: `“Siempre me ha costado elegir el coche adecuado. ${process.env.VUE_APP_TITLE} me ayudó rápido y gratis.”`,
          image: "/img/testi-2.a6abc48c.jpg",
        },
        {
          name: "Laura Palma",
          description:
            "“Una maravilla, te muestran justo lo que necesitas según tu perfil. Súper recomendado.”",
          image: "/img/testi-3.62c4ea23.jpg",
        },
      ],
    };
  },
  watch: {
    expandedKeys(newExpanded,oldExpanded) {
    if(!_.isEmpty(_.omit(newExpanded, _.keys(oldExpanded))))  this.expandedKeys = _.omit(newExpanded, _.keys(oldExpanded));
    }
  },
  mounted() {
    FacebookServices.pageView();
    this.initParticles();
    sessionStorage.clear();
    localStorage.clear();
    const urlParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem("SID", urlParams.get("SID") || "");
    sessionStorage.setItem("CHID", urlParams.get("CHID") || "");

    (window as any).googletag = (window as any).googletag || { cmd: [] };
    (window as any).googletag.cmd.push(() => {
      const anchorSlot = (window as any).googletag.defineOutOfPageSlot(
        this.AdGoatConfig.anchorPath,
        (window as any).googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
      );

      if (anchorSlot) {
        anchorSlot
          .setTargeting("home", "anchor")
          .addService((window as any).googletag.pubads());
      }

      (window as any).googletag.enableServices();

      (window as any).googletag.display(anchorSlot);
    });
  },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 250,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 10,
            },
          },
          opacity: {
            value: 0.75,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              opacity_min: 0.2,
              sync: true,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 0,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
});
